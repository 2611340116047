<template>
  <!-- out which, I am only applying Bootstrap to pages which use it -->

  <link
    rel="stylesheet"
    href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.3/dist/css/bootstrap.min.css"
    integrity="sha384-QWTKZyjpPEjISv5WaRU9OFeRpok6YctnYmDr5pNlyT2bRjXh0JMhjY6hW+ALEwIH"
    crossorigin="anonymous"
  />
  <!-- See: `getFullVh()` -->
  <div style="overflow: hidden; height: 0">
    <div id="measure-vh" style="position: fixed; height: 100vh"></div>
  </div>

  <!-- showPopUpOk --><transition name="fade">
    <div
      v-show="showPopUpOk"
      class="modal-overlayfrombottomblur"
      :class="this.showOld === true ? 'wrapper-todiv' : 'wrapper-todiv-new'"
      style="z-index: 999"
    >
      <div
        class="modalnotbootstrap"
        style="
          padding-top: 3vh;
          padding-bottom: 3vh;
          min-height: 40vh;
          width: 80%;
        "
      >
        <!-- put in table for dev speed need to learn more about flex -->
        <table border="0" width="80%">
          <tr>
            <td>
              <div
                class="lt-blue txtc"
                :style="{
                  'font-size': this.fontSizeTitle + 'vh',
                  'line-height': this.fontSizeTitleLineHeight + 'vh',
                }"
              >
                {{ this.popUpMsgTitle }}
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div
                class="lt-blue txtc"
                :style="{
                  'font-size': this.fontSizePopUpMsg + 'vh',
                  'line-height': this.fontSizePopUpMsgLineHeight + 'vh',
                  'padding-top': '1vh',
                }"
                style="overflow-wrap: anywhere"
              >
                {{ this.popUpMsgBody }}
              </div>
            </td>
          </tr>
          <tr v-show="!this.suppressOk">
            <td style="height: 10vh">
              <div>
                <button
                  class="widebtn"
                  ref="show-popup-ok"
                  style="width: 50%; height: 6vh"
                  @click="this.doPopUpOk()"
                >
                  Ok
                </button>
              </div>
            </td>
          </tr>
          <tr>
            <!-- spacer -->
            <td><div></div></td>
          </tr>
        </table>
      </div>
    </div>
  </transition>
  <div>
    <!-- how or dont show whole new home page -->

    <div
      class="container text-center"
      style="padding: 0vw; outline: 0.0em dashed orange"
    >
      <NavigationBar
        @do-show-home="doShowHome"
        @do-show-gettipsstart="doShowGetTipsStartNew"
        @do-show-givetips="doGiveTipsShowMoreInfoNew"
        @do-show-gettips="doGetTipsShowMoreInfoNew"
        @do-show-tipwithoutscanning="doShowTipWithoutScanning"
        @do-show-account="doShowAccount"
        @do-download-brochure="doDownloadBrochure"
        @do-show-contact="doShowContactNew"
      />

      <div
        class="jumbotron text-center tugradient1"
        style="outline: 0.0em dashed red"
      >
        <div
          class="container mediaSelectDesktop"
          style="padding: 3vw; outline: 0.0em dashed blue"
        >
          <div class="row" style="outline: 0.0em dashed pink">
            <div v-if="this.nhbfmembers === true"
              class="offset-md-4 col-md-8 tufont tublue tumediumtitlebold tubestsolutiontitle text-middle"
              style="outline: 0.0em dashed purple; height: 60px "
            >
              Welcome NHBF member...
            </div>
            <div
              class="col-md-4 mainimageheight hairdresser2imagewidth "
              style="display: flex; flex-wrap: wrap; outline: 0.0em dashed green"
            >
              <img
                class="hairdresser2imagewidth fade-in-button-animation"
                style="
                  height: auto;
                  padding-top: 0vw;
                  padding-bottom: 1.5vw;
                  outline: 0.0em dashed purple;
                "
                alt="Hairdresser"
                src="../assets/hairdresser2.webp"
              />
              <span class="mediaSelectDesktop tufont tusmallerfontsize">
                If one of our standard packages below does not suit your
                needs, simply let us know your requirements.</span
              >
            </div>

            <!-- <div class="col-sm-8 col-md-8 text-start" style="padding-left: 3vw; padding-right: 6vw; outline:0.0em dashed orange; height: auto"> -->

            <div
              class="col-8 col-md-8 tufont tumediumtitlebold tubestsolutiontitle text-middle transparent-simple-animation"
              style="outline: 0.0em dashed purple; height: auto"
            >
              Getting set up with thankU is this easy!
            </div>
            <HomePageTickedListItem
              ListItemString="Decide below which thankU QR media you would like"
            />
            <HomePageTickedListItem
              ListItemString="Click a 'Get set up for free!' button, for your media at no cost"
            />
            <HomePageTickedListItem
              ListItemString="Complete the form below to tell us your selection and where to send the media"
            />
            <HomePageTickedListItem
              ListItemString="We send you your thankU QR media by post, and a staff set up email"
            />
            <HomePageTickedListItem
              ListItemString="Staff connect themselves to get ready for tips in 1 minute each"
            />
          </div>
          <br />

          <div
            class="row col-sm-12 col-md-8 offset-md-4 tufont tutablepadding text-start tusmallerfontsize"
            style="outline: 0.0em dashed red; height: auto; padding-top: 1vh"
          >
            <span
              class="col-9 offset-2 col-md-10 offset-md-1 tufont;"
              style="outline: 0.0em dashed green"
            >
              <div
                class="bottombuttonwidth"
                style="padding-top: 1vh; margin: auto"
              >
                <span></span
                ><button
                  class="tuhomepagesignupbutton turegularfontsize fade-in-button-animation"
                  style="width: 100%"
                  @click="this.doOrderBadges()"
                >
                  Get set up for free!
                </button>
              </div>
            </span>
          </div>
          <div class="mediaSelectDesktop">
            <div
              class="container"
              style="padding: 3vw; outline: 0.0em dashed blue"
            >
              <div class="row" style="outline: 0.0em dashed pink"></div>
            </div>
            <div class="row" style="height: 0vw !important">
              <div class="col-md-1" style="outline: 0.0em dashed green">
                <img
                  class="smile1"
                  alt="thankU smile right"
                  src="../assets/tusmileonly.png"
                />
              </div>
              <div class="col-md-1" style="outline: 0.0em dashed green">
                <img
                  class="smile2"
                  alt="thankU smile right"
                  src="../assets/tusmileonly.png"
                />
              </div>
            </div>
          </div>
          <div
            class="row col-sm-12 col-md-8 offset-md-4 tufont tutablepadding text-center tusmallerfontsize"
            style="
              outline: 0.0em dashed red;
              height: auto;
              padding-top: 1vh;
              margin-top: -6vw;
            "
          >
            <span
              class="col-9 offset-2 col-md-6 offset-md-3 tufont;"
              style="padding-top: 1.5vw; outline: 0.0em dashed green"
            >
              thankU is currently providing its thankU QR badge media to you
              <b>free of charge</b> for a limited time only. Simply tell us how
              many of each format you would like, and the address to send them
              to - that's it!
            </span>
          </div>
          <div
            class="row col-sm-12 col-md-8 offset-md-4 tufont tutablepadding text-center tusmallerfontsize"
            style="outline: 0.0em dashed red; height: auto; padding-top: 1vh"
          >
            <span
              class="col-9 offset-2 col-md-6 offset-md-3 tufont;"
              style="padding-top: 0vw; outline: 0.0em dashed green"
            >
              Fees are payable on tip transactions, most often paid by the
              tipper, but when not, fees are deducted from the tip. For FAQ on
              recieving tips, please see
              <span
                class="text-only-button-new cancel"
                @click="doGetTipsShowMoreInfoNew()"
                style="pointer: cursor"
                ><b>here</b></span
              >, and full information specifically on fees, please see
              <span
                class="text-only-button-new cancel"
                @click="doGetTipsShowMoreInfoFeesNew()"
                style="pointer: cursor"
                ><b>here</b></span
              >.
            </span>
            <div
              class="col-12 tufont tutablepadding tusmallerfontsize"
              style="
                outline: 0.0em dashed red;
                height: auto;
                float: left;
                padding-top: 1vh;
              "
            >
              <button
                class="tuhomepagebutton turegularfontsize fade-in-button-animation"
                @click="this.doDownloadBrochure()"
              >
                Download our brochure
              </button>
            </div>
          </div>
        </div>
        <div
          class="container mediaSelectPhone"
          style="padding: 3vw; outline: 0.0em dashed blue"
        >
          <div class="mediaSelectPhone container">
            <div class="row" style="display: none; outline: 0.0em dashed pink">
              <div
                class="offset-0 col-3"
                style="height: 0px; outline: 0.0em dashed green"
              >
                <img
                  class="smile1"
                  style="outline: 0.5em dashed green"
                  alt="thankU smile"
                  src="../assets/tusmileonly.png"
                />
              </div>

              <div>&nbsp;</div>

              <div
                class="offset-0 col-12 float-right"
                style="height: 0px; outline: 0.0em dashed green"
              >
                <img
                  class="smile2"
                  alt="thankU smile"
                  src="../assets/tusmileonly.png"
                />
              </div>
            </div>
          </div>
          <div v-if="this.nhbfmembers === true"
            class="col-12 tufont tublue tumediumtitlebold tubestsolutiontitle text-middle"
            style="outline: 0.0em dashed purple; height: 60px "
          >
            Welcome NHBF member...
          </div>
          <div
            class="row"
            style="display: flex; flex-wrap: wrap; outline: 0.0em dashed pink"
          >
            <div
              class="col-7 offset-0 mainimageheight hairdresser2imagewidth"
              style="display: flex; flex-wrap: wrap; outline: 0.0em dashed green"
            >
              <img
                class="hairdresser2imagewidth fade-in-button-animation"
                style="
                  height: auto;
                  padding-top: 0vw;
                  padding-bottom: 1.5vw;
                  outline: 0.0em dashed purple;
                "
                alt="Hairdresser"
                src="../assets/hairdresser2.webp"
              />
              <span class="mediaSelectDesktop tufont tusmallerfontsize">
                <br />If one of our standard packages below does not suit your
                needs, simply let us know your requirements.</span
              >
            </div>

            <div
              class="col-5 tufont tumediumtitleboldbigger text-middle transparent-simple-animation"
              style="
                outline: 0.0em dashed purple;
                padding-left: 0vw;
                height: auto;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
              "
            >
              Getting set up with thankU is this easy!
            </div>

            <!-- <div class="col-sm-8 col-md-8 text-start" style="padding-left: 3vw; padding-right: 6vw; outline:0.0em dashed orange; height: auto"> -->

            <HomePageTickedListItem
              ListItemString="Decide below which thankU QR media you would like"
            />
            <HomePageTickedListItem
              ListItemString="Click a 'Get set up for free!' button, for your media at no cost"
            />
            <HomePageTickedListItem
              ListItemString="Complete the form below to tell us your selection and where to send the media"
            />
            <HomePageTickedListItem
              ListItemString="We send you your thankU QR media by post, and a staff set up email"
            />
            <HomePageTickedListItem
              ListItemString="Staff connect themselves to get ready for tips in 1 minute each"
            />
          </div>
          <br />

          <div
            class="row col-12 col-md-8 offset-md-4 tufont tutablepadding text-start tusmallerfontsize"
            style="outline: 0.0em dashed red; height: auto; padding-top: 1vh"
          >
            <span
              class="col-9 offset-2 col-md-10 offset-md-1 tufont;"
              style="outline: 0.0em dashed green"
            >
              <div
                class="bottombuttonwidth"
                style="padding-top: 1vh; margin: auto"
              >
                <span></span
                ><button
                  class="tuhomepagesignupbutton turegularfontsize fade-in-button-animation"
                  style="width: 100%"
                  @click="this.doOrderBadges()"
                >
                  Get set up for free!
                </button>
              </div>
            </span>
          </div>
          <div
            class="row col-12 col-md-8 offset-md-4 tufont text-center tusmallerfontsize"
            style="outline: 0.0em dashed red; height: auto; padding-top: 1vh"
          >
            <span
              class="col-9 offset-2 col-md-6 offset-md-3 tufont;"
              style="
                padding-top: 1.5vw;
                padding-left: 0vw;
                padding-right: 0vw;
                outline: 0.0em dashed green;
              "
            >
              thankU is currently providing its thankU QR badge media to you
              <b>free of charge</b> for a limited time only. Simply tell us how
              many of each format you would like, and the address to send them
              to - that's it!
            </span>
          </div>
          <div
            class="row col-12 col-md-8 offset-md-4 tufont text-center tusmallerfontsize"
            style="outline: 0.0em dashed red; height: auto; padding-top: 1vh"
          >
            <span
              class="col-9 offset-2 col-md-6 offset-md-3 tufont;"
              style="
                padding-top: 1.5vw;
                padding-left: 0vw;
                padding-right: 0vw;
                outline: 0.0em dashed green;
              "
            >
              Fees are payable on tip transactions, most often paid by the
              tipper, but when not, fees are deducted from the tip. For FAQ on
              recieving tips, please see
              <span
                class="text-only-button-new cancel"
                @click="doGetTipsShowMoreInfoNew()"
                style="pointer: cursor"
                ><b>here</b></span
              >, and full information specifically on fees, please see
              <span
                class="text-only-button-new cancel"
                @click="doGetTipsShowMoreInfoFeesNew()"
                style="pointer: cursor"
                ><b>here</b></span
              >.
            </span>
          </div>
          <div
            class="col-12 tufont tusmallerfontsize"
            style="
              outline: 0.0em dashed red;
              height: auto;
              padding-top: 1vh;
              padding-left: 0vw;
              pading-right: 0vw;
              outline: 0.0em dashed green;
            "
          >
            <button
              class="tuhomepagebutton turegularfontsize tugettipspagebuttonwidth fade-in-button-animation"
              @click="this.doDownloadBrochure()"
            >
              Download our brochure
            </button>
          </div>
        </div>
        <div class="container" style="padding-top: 1vw">
          <div class="row">
            <div
              class="col-12 offset-0 col-md-12 offset-md-0 tuwhitebox"
              style="width: 90vw; margin: auto; outline: 0.0em dotted black"
            >
              <div class="tufont tumediumtitlebold">Regular package</div>
              <div
                class="col-md-8 offset-md-2 tufont tusmallerfontsize"
                style="padding-top: 1vw; padding-bottom: 1vw"
              >
                This package is widely used in salons, however we have a number
                of options other than 'stylist', including 'therapist',
                'technician', 'artist', 'host', 'waiter', 'driver', 'team', or
                non-specific.
              </div>
              <div
                class="container"
                style="
                  display: flex;
                  flex-wrap: wrap;
                  outline: 0.0em dashed green;
                "
              >
                <div class="col-12 col-md-6" style="outline: 0.0em dashed pink">
                  <table
                    class="table"
                    border="0"
                    style="width: 100%; outline: 0.0em dashed red"
                  >
                    <tr>
                      <td
                        style="padding-bottom: 0.2vw; outline: 0.0em dashed pink"
                      >
                        Front Desk Stand
                      </td>
                    </tr>
                    <tr>
                      <td style="padding-bottom: 1vw; outline: 0.0em dashed red">
                        <div
                          class="col-md-8 offset-md-2 tufont tusmallestfontsize"
                          style="outline: 0.0em dashed brown"
                        >
                          Typically placed at your reception / payments desk,
                          our stylish Front Desk Stand announces to your clients
                          that you have a tipping service available for them.
                          Dimensions: 14.8 x 10.5cm.
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <a v-bind:href="frontdeskfull"
                          ><img
                            class="productImageWidth"
                            style="
                              height: auto;
                              padding-top: 0vw;
                              outline: 0.0em dashed purple;
                            "
                            alt="Window sticker"
                            src="../assets/frontdesklessrounded.webp"
                          />
                        </a>
                      </td>
                    </tr>
                  </table>
                </div>

                <div class="col-12 col-md-6" style="outline: 0.0em dashed red">
                  <table
                    class="table"
                    border="0"
                    style="width: 100%; outline: 0.0em dashed red"
                  >
                    <tr>
                      <td
                        style="padding-bottom: 0.2vw; outline: 0.0em dashed pink"
                      >
                        <b>Optional:</b> "No Glue" Mirror Sticker
                      </td>
                    </tr>
                    <tr>
                      <td style="padding-bottom: 1vw; outline: 0.0em dashed red">
                        <div
                          class="col-md-8 offset-md-2 tufont tusmallestfontsize"
                          style="outline: 0.0em dashed brown"
                        >
                          <b>At your option:</b> mount our "No Glue" Mirror
                          Stickers on the mirror in front of the client. Some
                          salons choose this option, others do not, it's a
                          matter of personal preference.
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <a v-bind:href="stickerfull"
                          ><img
                            class="productImageWidth"
                            style="
                              height: auto;
                              padding-top: 0vw;
                              outline: 0.0em dashed purple;
                            "
                            alt="Window sticker"
                            src="../assets/stickerrounded.webp"
                          />
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td
                        style="
                          padding-top: 2vw;
                          padding-bottom: 1vw;
                          outline: 0.0em dashed red;
                        "
                      >
                        <div
                          class="col-md-8 offset-md-2 tufont tusmallestfontsize"
                          style="outline: 0.0em dashed brown"
                        >
                          <b>How to mount:</b> adheres to the surface of
                          client-facing mirror without adhesive and is easily
                          removable – simply moisten glass of mirror with damp
                          kitchen roll or similar, then mount the ‘sticker’ on
                          the glass, gently pressing out any obvious bubbles
                          with your finger – dab any remaining moisture on the
                          surface of the sticker or mirror away if necessary.
                          Dimensions: 12cm x 8cm.
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td
                        style="
                          padding-top: 2vw;
                          padding-bottom: 1vw;
                          outline: 0.0em dashed red;
                        "
                      >
                        <div
                          class="col-md-8 offset-md-2 tufont tusmallestfontsize"
                          style="outline: 0.0em dashed brown"
                        >
                          <b
                            >We also have wristworn and stickable thankU QR
                            media, please phone or email us to find out more.</b
                          >
                        </div>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
            <div class="col-md-1"></div>
            <div
              class="col-md-6 offset-md-2 tuwhitebox discreetpackagephonewidth"
              style="margin-top: 3vw !important; padding-top: 1vw; outline: 0.0em dotted black"
            >
              <div class="tufont tumediumtitlebold" style="padding-bottom: 1vw">
                Discrete package
              </div>

              <div class="container">
                <div class="col-12 col-md-12" style="outline: 0.0em dashed red">
                  <table
                    border="0"
                    style="width: 100%; outline: 0.0em dashed red"
                  >
                    <tr>
                      <td style="padding-bottom: 1vw">Tabletop Stand</td>
                    </tr>

                    <tr>
                      <td style="padding-bottom: 1vw; outline: 0.0em dashed red">
                        <div
                          class="col-md-8 offset-md-2 tufont tusmallestfontsize"
                          style="outline: 0.0em dashed brown"
                        >
                          If you just want to keep the presentation of thankU
                          very discreet, use our sleek Tabletop Stand instead of
                          the Front Desk Stand, which you can direct your
                          clients to if they ask how they can tip. Dimensions:
                          6cm x 4.3cm.
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <a v-bind:href="countersquarefull"
                          ><img
                            class="productImageWidthCountertop"
                            style="
                              height: auto;
                              padding-top: 0vw;
                              outline: 0.0em dashed purple;
                            "
                            alt="Window sticker"
                            src="../assets/countersquarerounded.webp"
                          />
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td
                        style="
                          padding-top: 2vw;
                          padding-bottom: 1vw;
                          outline: 0.0em dashed red;
                        "
                      >
                        <div
                          class="col-md-8 offset-md-2 tufont tusmallestfontsize"
                          style="outline: 0.0em dashed brown"
                        >
                          We supply up to 3 Tabletop Stands at no charge.
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td
                        style="
                          padding-top: 0.3vw;
                          padding-bottom: 1vw;
                          outline: 0.0em dashed red;
                        "
                      >
                        <div
                          class="col-md-8 offset-md-2 tufont tusmallestfontsize"
                          style="outline: 0.0em dashed brown"
                        >
                          <b
                            >We also have wearable and stickable thankU QR
                            media, based on the blue thankU QR badge shown above
                            - please phone or email us to find out more.</b
                          >
                        </div>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div ref="orderForm"></div>
        <div
          v-show="this.showOrderForm === true"
          class="col-md-8 offset-md-2 tugreybluebox orderformphonewidth"
          style="margin-top: 3vw !important; outline: 0.0em dotted black"
        >
          <table style="width: 100%; "> <!-- background: #6bc720 -->
            <tr>
              <td>
                <div class="tufont tumediumtitlebold">
                  Order your free thankU QR media
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div
                  style="
                    display: flex;
                    flex-wrap: wrap;
                    outline: 0.0em dashed red;
                  "
                >
                  <span
                    class="col-10 offset-1 col-md-8 offset-md-2"
                    style="height: 6vh; display: flex; padding-bottom: 0vw"
                  >
                    <select
                      class="tugettipspage-textbox input"
                      style="max-width: 100%; width: 100%; "
                      ref="changePackage"
                      @change="doDetectSelectChangePackage()"
                    >
                      <option value="regular">
                        -- Choose thankU QR media package --
                      </option>
                      <option value="regular">
                        Regular thankU QR media package
                      </option>
                      <option value="discreet">
                        Discreet thankU QR media package
                      </option>
                      <option value="custom">
                        Custom - tell us in the Notes box below
                      </option>
                    </select>
                  </span>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div
                  style="
                    display: flex;
                    flex-wrap: wrap;
                    outline: 0.0em dashed red;
                  "
                >
                  <span
                    class="col-10 offset-1 col-md-8 offset-md-2"
                    style="height: 6vh; display: flex; padding-bottom: 0vw"
                  >
                    <select
                      class="tugettipspage-textbox input"
                      style="max-width: 100%; width: 100%"
                      ref="changeBusinessType"
                      @change="doDetectSelectChangeBusinessType()"
                    >
                      <option value="salon">
                        -- Select your business type --
                      </option>
                      <option value="salon">Salon</option>
                      <option value="hotel">Hotel</option>
                      <option value="restaurant">Restaurant</option>
                      <option value="pub">Pub</option>
                      <option value="other">Other</option>
                    </select>
                  </span>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div
                  style="
                    display: flex;
                    flex-wrap: wrap;
                    outline: 0.0em dashed red;
                  "
                >
                  <span
                    class="col-10 offset-1 col-md-8 offset-md-2"
                    style="height: 6vh; display: flex; padding-bottom: 0vw"
                  >
                    <select
                      class="tugettipspage-textbox input"
                      style="max-width: 100%; width: 100%"
                      ref="changeDescriptionRecipient"
                      @change="doDetectSelectChangeDescriptionRecipient()"
                    >
                      <option value="stylist">
                        -- Select description for main type of recipient --
                      </option>
                      <option value="stylist">Stylist</option>
                      <option value="therapist">Therapist</option>
                      <option value="technician">Technician</option>
                      <option value="artist">Artist</option>
                      <option value="host">Host</option>
                      <option value="waiter">Waiter</option>
                      <option value="driver">Driver</option>
                      <option value="team">Team</option>
                      <option value="non-specific">Non-specific</option>
                    </select>
                  </span>
                </div>
              </td>
            </tr>
            <tr v-show="this.showStickers === true">
              <td>
                <div
                  style="
                    display: flex;
                    flex-wrap: wrap;
                    outline: 0.0em dashed red;
                  "
                >
                  <span
                    class="col-10 offset-1 col-md-8 offset-md-2"
                    style="height: 6vh; display: flex; padding-bottom: 0vw"
                  >
                    <select
                      class="tugettipspage-textbox input"
                      style="max-width: 100%; width: 100%"
                      ref="changeNumberOfStickers"
                      @change="doDetectSelectChangeNumberOfStickers()"
                    >
                      <option value="0">
                        -- Select number of "No Glue" Mirror Stickers --
                      </option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                      <option value="6">6</option>
                      <option value="7">7</option>
                      <option value="8">8</option>
                      <option value="9">9</option>
                      <option value="10">10</option>
                      <option value="11">11</option>
                      <option value="12">12</option>
                      <option value="13">13</option>
                      <option value="14">14</option>
                      <option value="15">15</option>
                      <option value="16">16</option>
                      <option value="17">17</option>
                      <option value="18">18</option>
                      <option value="19">19</option>
                      <option value="20">20</option>
                      <option value="21">21</option>
                      <option value="22">22</option>
                      <option value="23">23</option>
                      <option value="24">24</option>
                      <option value="25">25</option>
                      <option value="26">26</option>
                      <option value="27">27</option>
                      <option value="28">28</option>
                      <option value="29">29</option>
                      <option value="30">30</option>
                      <option value="31">More than 30</option>
                    </select>
                  </span>
                </div>
              </td>
            </tr>
            <tr v-show="this.showTableTop === true">
              <td>
                <div
                  style="
                    display: flex;
                    flex-wrap: wrap;
                    outline: 0.0em dashed red;
                  "
                >
                  <span
                    class="col-md-8 offset-md-2"
                    style="height: 6vh; display: flex; padding-bottom: 0vw"
                  >
                    <select
                      class="tugettipspage-textbox input"
                      style="max-width: 100%; width: 100%"
                      ref="changeNumberOfTabletopStands"
                      @change="doDetectSelectChangeNumberOfTabletopStands()"
                    >
                      <option value="regular">
                        -- Select number of Tabletop Stands --
                      </option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">More than 3</option>
                    </select>
                  </span>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div
                  style="
                    display: flex;
                    flex-wrap: wrap;
                    outline: 0.0em dashed red;
                  "
                >
                  <span
                    class="col-10 offset-1 col-md-8 offset-md-2"
                    style="height: 12vh; display: flex; padding-bottom: 0vw"
                  >
                    <textarea
                      type="textbox"
                      class="tugettipspage-textbox input"
                      style="height: 12vh; max-width: 100%; width: 100%"
                      ref="orderNotes"
                      placeholder="Notes - if you need to let us know more about your requirements."
                    />
                  </span>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div
                  style="
                    display: flex;
                    flex-wrap: wrap;
                    outline: 0.0em dashed red;
                  "
                >
                  <span
                    class="col-10 offset-1 col-md-8 offset-md-2"
                    style="height: 6vh; display: flex; padding-bottom: 0vw"
                  >
                    <input
                      type="textbox"
                      class="tugettipspage-textbox input"
                      style="max-width: 100%; width: 100%"
                      ref="orderName"
                      placeholder="Your name"
                    />
                  </span>
                </div>
              </td>
            </tr>

            <tr>
              <td>
                <div
                  style="
                    display: flex;
                    flex-wrap: wrap;
                    outline: 0.0em dashed red;
                  "
                >
                  <span
                    class="col-10 offset-1 col-md-8 offset-md-2"
                    style="height: 6vh; display: flex; padding-bottom: 0vw"
                  >
                    <input
                      type="textbox"
                      class="tugettipspage-textbox input"
                      style="max-width: 100%; width: 100%"
                      ref="orderBusinessName"
                      placeholder="Name of your business"
                    />
                  </span>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div
                  style="
                    display: flex;
                    flex-wrap: wrap;
                    outline: 0.0em dashed red;
                  "
                >
                  <span
                    class="col-10 offset-1 col-md-8 offset-md-2"
                    style="height: 6vh; display: flex; padding-bottom: 0vw"
                  >
                    <input
                      type="textbox"
                      class="tugettipspage-textbox input"
                      style="max-width: 100%; width: 100%"
                      ref="orderAddress"
                      placeholder="Address to send your thankU QR media package to"
                    />
                  </span>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div
                  style="
                    display: flex;
                    flex-wrap: wrap;
                    outline: 0.0em dashed red;
                  "
                >
                  <span
                    class="col-10 offset-1 col-md-8 offset-md-2"
                    style="height: 6vh; display: flex; padding-bottom: 0vw"
                  >
                    <input
                      type="email"
                      class="tugettipspage-textbox input"
                      style="max-width: 100%; width: 100%"
                      ref="orderEmail"
                      placeholder="Your email address"
                    />
                  </span>
                </div>
              </td>
            </tr>

            <tr>
              <td>
                <div
                  style="
                    display: flex;
                    flex-wrap: wrap;
                    outline: 0.0em dashed red;
                  "
                >
                  <span
                    class="col-10 offset-1 col-md-8 offset-md-2"
                    style="height: 6vh; display: flex; padding-bottom: 0vw"
                  >
                    <input
                      type="textbox"
                      class="tugettipspage-textbox input"
                      style="max-width: 100%; width: 100%"
                      ref="orderPhoneNumber"
                      placeholder="Optional: your phone number"
                    />
                  </span>
                </div>
              </td>
            </tr>
            <tr>
              <td style="display: flex">
                <div
                  class="lt-blue notes-text"
                  style="width: 90%; padding-top: 0vw"
                >
                  Your email address and phone number are <b>never</b> shared
                  with anyone
                </div>
                <br />
              </td>
            </tr>

            <tr>
              <td style="display: flex">
                <div
                  class="lt-blue notes-text"
                  style="width: 90%; padding-top: 0vw"
                >
                  <b>Any questions?</b> Please call us on +442078560378 or email
                  us at
                  <a
                    href="mailto:mailorders@thanku.app?subject=get thankU enquiry&body=Please let us know your query or requirements: %0D%0A%0D%0A%0D%0AThank you! best wishes, the thankU Team"
                    >mailorders@thanku.app</a
                  >
                </div>
                <br />
              </td>
            </tr>
            <tr>
              <td style="height: 12vh">
                <div>
                  <button
                    class="tuhomepagebutton tugettipspagebuttonwidth"
                    @click="this.doSubmitOrder()"
                  >
                    Submit your order
                  </button>
                </div>
              </td>
            </tr>
          </table>
        </div>
        <div
          v-show="this.showOrderCompleted === true"
          class="col-md-8 offset-md-2 tugreybluebox"
          style="margin-top: 3vw"
        >
          <div class="tufont tumediumtitlebold" style="padding-bottom: 2vw">
            Thank you for your order
          </div>

          <div ref="orderDiv">
            <OrderItem
              title="Package ordered"
              :orderItem="this.orderedPackage"
            />
            <OrderItem
              title="Business type"
              :orderItem="this.orderedBusinessType"
            />
            <OrderItem
              title="Description of main recipient"
              :orderItem="this.orderedDescriptionRecipient"
            />
            <OrderItem
              v-show="this.showStickers === true"
              title='Number of "No Glue" Mirror Stickers'
              :orderItem="this.orderedNumberOfStickers"
            />
            <OrderItem
              v-show="this.showTableTop === true"
              title="Number of Tabletop Stands"
              :orderItem="this.orderedNumberOfTabletopStands"
            />
            <OrderItem title="Order notes" :orderItem="this.orderedNotes" />
            <OrderItem title="Your name" :orderItem="this.orderedName" />
            <OrderItem
              title="Business name"
              :orderItem="this.orderedBusinessName"
            />
            <OrderItem
              title="Address to send media"
              :orderItem="this.orderedAddress"
            />
            <OrderItem title="Email" :orderItem="this.orderedEmail" />
            <OrderItem title="Phone" :orderItem="this.orderedPhoneNumber" />
          </div>
          <table style="width: 100%; margin-top: 2vw">
            <tr>
              <td style="display: flex">
                <div
                  class="lt-blue notes-text"
                  style="width: 60%; padding-top: 0vw; line-height: 1px"
                >
                  <b>Any questions?</b> Please call us on +442078560378 or email
                  us at
                  <a
                    href="mailto:mailorders@thanku.app?subject=get thankU enquiry&body=Please let us know your query or requirements: %0D%0A%0D%0A%0D%0AThank you! best wishes, the thankU Team"
                    >mailorders@thanku.app</a
                  >
                </div>
                <br />
              </td>
            </tr>
          </table>
        </div>
      </div>

    </div>
    <br />
      <HomeFooter
        @do-show-contact="doShowContactNew"
        @do-show-about="doShowAboutNew"
        @do-show-privacy="doShowPrivacyNew"
        @do-show-terms="doShowTermsNew"
      />
  </div>
</template>

<script>
const Parse = require("parse/node");
import shared from "../shared.js";
import LogRocket from "logrocket";

import { defineAsyncComponent } from "vue";

const NavigationBar = defineAsyncComponent({
  loader: () => import("../components/NavigationBar.vue"),
});

const HomePageTickedListItem = defineAsyncComponent({
  loader: () => import("../components/HomePageTickedListItem.vue"),
});

const OrderItem = defineAsyncComponent({
  loader: () => import("../components/OrderItem.vue"),
});

const HomeFooter = defineAsyncComponent({
  loader: () => import("../components/HomeFooter.vue"),
});

export default {
  inject: ["globalPushForwardInterval", "devEnv", "appId", "appJSKey"],
  data() {
    return {
      showPopUpOk: false,
      popUpMsgTitle: "",
      popUpMsgBody: "",
      fontSizeTitle: 2.7, // vh
      fontSizeTitleLineHeight: 5, //vh
      fontSizePopUpMsg: 2.3, // vh
      fontSizePopUpMsgLineHeight: 3.4, // vh

      fontSizeNormal: 3.0, // vh
      fontSizeNormalInPXToUse: "",
      fontSizeNormalLineHeight: 4.0, // vh
      fontSizeNormalInPXLineHeightToUse: "",
      nhbfmembers: false,
      // NEW
      showStickers: true,
      showTableTop: false,
      showOrderForm: true,
      showOrderCompleted: false,
      orderedPackage: "",
      orderedBusinessType: "",
      orderedDescriptionRecipient: "",
      orderedNumberOfStickers: "",
      orderedNumberOfTabletopStands: "",
      orderedNotes: "",
      orderedName: "",
      orderedBusinessName: "",
      orderedAddress: "",
      orderedEmail: "",
      orderedPhoneNumber: "",
    };
  },
  components: {
    NavigationBar,
    HomePageTickedListItem,
    HomeFooter,
    OrderItem,
  },
  computed: {
    frontdeskfull() {
      return require("@/assets/frontdeskfull.jpg");
    },
    stickerfull() {
      return require("@/assets/stickerfull.jpg");
    },
    countersquarefull() {
      return require("@/assets/countersquare.webp");
    },
  },
  methods: {
    // NEW
    doShowHome() {
      console.log("ok let's doShowHome NEW");
      let returnJSON = { showHomeNew: true, fromScreen: "getTipsStartNew" };
      this.$emit("return-json", returnJSON); // this is activating the router so no need to go back
    },
    doShowAccount(){
      console.log("ok let's doShowAccount");
      let returnJSON = { showAccount: true, fromScreen: "getTipsStartNew"  };
      this.$emit('return-json', returnJSON); // this is activating the router so no need to go back
      
    },
    doShowTipWithoutScanning(){
      // console.log("initiating showTipWithoutScanning on Home");
      let returnJSON = { showTipWithoutScanning: true, fromScreen: "getTipsStartNew" };
      this.$emit('return-json', returnJSON); // this is activating the router so no need to go back
    },
    doGiveTipsShowMoreInfoNew() {
      console.log("show more info NEW! .. on doGiveTipsShowMoreInfoNew");

      let returnJSON = {
        showMoreGiveInfoNew: true,
        fromScreen: "getTipsStartNew",
      };
      this.$emit("return-json", returnJSON);
    },
    doGetTipsShowMoreInfoNew() {
      console.log("show more info NEW! .. on doGetTipsShowMoreInfoNew");

      let returnJSON = {
        showMoreGetInfoNew: true,
        fromScreen: "getTipsStartNew",
      };
      this.$emit("return-json", returnJSON);
    },
    doGetTipsShowMoreInfoFeesNew() {
      console.log("show more info plus fees NEW!");

      let returnJSON = {
        showMoreGetInfoNew: true,
        feesShow: true,
        fromScreen: "getTipsStartNew",
      };
      this.$emit("return-json", returnJSON);
    },
    doShowContactNew() {
      let returnJSON = { showContactNew: true, fromScreen: "getTipsStartNew" };
      this.$emit("return-json", returnJSON); // this is activating the router so no need to go back
    },
    doPopUpOk() {
      this.showPopUpOk = false;
    },
    doDetectSelectChangePackage() {
      let orderPackage = this.$refs.changePackage.value;

      if (orderPackage === "regular") {
        this.showStickers = true;
        this.showTableTop = false;
      } else if (orderPackage === "discreet") {
        this.showStickers = false;
        this.showTableTop = true;
      } else {
        this.showStickers = true;
        this.showTableTop = true;
      }
    },

    doSubmitOrder() {
      console.log("submitting order");

      if (this.$refs.orderName.value === "") {
        this.popUpMsgTitle = "Info required";
        this.popUpMsgBody =
          "Please provide your name so we can contact you, if necessary, about your order";
        this.showPopUpOk = true;
      } else if (this.$refs.orderBusinessName.value === "") {
        this.popUpMsgTitle = "Info required";
        this.popUpMsgBody =
          "Please provide your business name - normally the name of your brand as it will be shown on thankU";
        this.showPopUpOk = true;
      } else if (this.$refs.orderAddress.value === "") {
        this.popUpMsgTitle = "Info required";
        this.popUpMsgBody =
          "Please provide the address you would like us to send your thankU QR media package to";
        this.showPopUpOk = true;
      } else if (this.$refs.orderEmail.value === "") {
        this.popUpMsgTitle = "Info required";
        this.popUpMsgBody =
          "Please provide your email so that we can update you on your order";
        this.showPopUpOk = true;
      } else if (shared.validEmail(this.$refs.orderEmail.value) === false) {
        this.popUpMsgTitle = "Sorry";
        this.popUpMsgBody =
          this.$refs.orderEmail.value + " is not a valid email address";
        this.showPopUpOk = true;
      } else {
        this.orderedPackage =
          this.$refs.changePackage.selectedIndex === 0
            ? this.$refs.changePackage.options[1].text
            : this.$refs.changePackage.options[
                this.$refs.changePackage.selectedIndex
              ].text;
        this.orderedBusinessType =
          this.$refs.changeBusinessType.selectedIndex === 0
            ? this.$refs.changeBusinessType.options[1].text
            : this.$refs.changeBusinessType.options[
                this.$refs.changeBusinessType.selectedIndex
              ].text;
        this.orderedDescriptionRecipient =
          this.$refs.changeDescriptionRecipient.selectedIndex === 0
            ? this.$refs.changeDescriptionRecipient.options[1].text
            : this.$refs.changeDescriptionRecipient.options[
                this.$refs.changeDescriptionRecipient.selectedIndex
              ].text;
        this.orderedNumberOfStickers =
          this.$refs.changeNumberOfStickers.value === "31"
            ? "more than 30"
            : this.$refs.changeNumberOfStickers.value;
        this.orderedNumberOfTabletopStands =
          this.$refs.changeNumberOfTabletopStands.value === "4"
            ? "more than 3"
            : this.$refs.changeNumberOfTabletopStands.value;
        this.orderedNotes = this.$refs.orderNotes.value;
        this.orderedName = this.$refs.orderName.value;
        this.orderedBusinessName = this.$refs.orderBusinessName.value;
        this.orderedAddress = this.$refs.orderAddress.value;
        this.orderedEmail = this.$refs.orderEmail.value;
        this.orderedPhoneNumber = this.$refs.orderPhoneNumber.value;

        this.showOrderForm = false;
        this.showOrderCompleted = true;

        this.$nextTick(() => {
          // for some reason needs this to pick up the dymanic values

          let mailHTML =
            '<table border=0 style="padding-top: 4px; padding-bottom: 4px"><tr>' +
            '<td style="width: 40%">Package ordered:</td><td style="width: 60%">' +
            this.orderedPackage +
            "</td></tr><tr>" +
            "<td>Business type:</td><td>" +
            this.orderedBusinessType +
            "</td></tr><tr>" +
            "<td>Description of main recipient:</td><td>" +
            this.orderedDescriptionRecipient +
            "</td></tr><tr>";

          if (this.showStickers === true) {
            mailHTML +=
              '<td>Number of "No Glue" Mirror Stickers:</td><td>' +
              this.orderedNumberOfStickers +
              "</td></tr><tr>";
          }

          if (this.showTableTop === true) {
            mailHTML +=
              "<td>Number of Tabletop Stands:</td><td>" +
              this.orderedNumberOfTabletopStands +
              "</td></tr><tr>";
          }

          mailHTML +=
            "<td>Order notes:</td><td>" +
            this.orderedNotes +
            "</td></tr><tr>" +
            "<td>Your name:</td><td>" +
            this.orderedName +
            "</td></tr><tr>" +
            "<td>Business name:</td><td>" +
            this.orderedBusinessName +
            "</td></tr><tr>" +
            "<td>Address to send media:</td><td>" +
            this.orderedAddress +
            "</td></tr><tr>" +
            "<td>Email:</td><td>" +
            this.orderedEmail +
            "</td></tr><tr>" +
            "<td>Phone:</td><td>" +
            this.orderedPhoneNumber +
            "</td></tr></table>";

          const params = {
            toEmail: this.orderedEmail,
            subject:
              "We have received your order for thankU Cashless Tipping QR media",
            body: mailHTML,
            orderName: this.orderedName,
            sgTemplateName: "sendMediaOrderedEmail2024",
          };

          Parse.Cloud.run("sendEmail", params);

          const params2 = {
            toEmail: "appalert@thanku.app",
            subject: "thankU ORDER RECEIVED",
            body: this.$refs.orderDiv.outerHTML,
          };

          Parse.Cloud.run("sendEmail", params2);
        });
      }
    },

    // OLD
    doFontSizeComputed() {
      this.fontSizeNormalInPXToUse = shared.convertVHSizeToMaxPixelsSize(
        this.fontSizeNormal,
        16
      );
      this.fontSizeNormalInPXLineHeightToUse =
        shared.convertVHSizeToMaxPixelsSize(this.fontSizeNormalLineHeight, 20);
    },
    doOrderBadges() {
      console.log("TAKE MY ORDER!");
      this.$refs.orderForm.scrollIntoView({ block: "start" });

    },
    doShowGetTipsStartNew() {
      console.log("TAKE MY ORDER!");
      this.$refs.orderForm.scrollIntoView({ block: "start" });
    },
    doDownloadBrochure() {
      const url = "https://www.thanku.app/introtothanku.pdf";

      let LRObjectId = undefined;

      if (window.localStorage.getItem("tuob") !== null) {
        LRObjectId = window.localStorage.getItem("tuob");
      } else if (
        window.localStorage.getItem("logRocketUserTimeStampId") !== null
      ) {
        LRObjectId = window.localStorage.getItem("logRocketUserTimeStampId");
      } else {
        window.localStorage.setItem(
          "logRocketUserTimeStampId",
          new Date().getTime()
        );
        LRObjectId = window.localStorage.getItem("logRocketUserTimeStampId");
      }

      let googleAdsOriginalReferrerURL =
        window.localStorage.getItem("googleAdsOriginalReferrerURL") !==
        undefined
          ? window.localStorage.getItem("googleAdsOriginalReferrerURL")
          : "n/a";

      // console.log("opening pdf");
      const params = {
        toEmail: "appalert@thanku.app",
        subject:
          "GREAT! Someone downloaded thankU pdf brochure on NEW ORDER PAGE",
        body: {
          type: "TappedDownloadThankUBrochure",
          googleAdsOriginalReferrerURL: googleAdsOriginalReferrerURL,
          referrerURL: window.localStorage.getItem("referrerURL"),
          LRObjectId: LRObjectId,
        },
      };

      Parse.Cloud.run("sendEmail", params);

      window.open(url, "_self");
    },
    doShowHolding() {
      shared.saveToUserPath(
        this.devEnv,
        "User wanted to start get tips process " + new Date().toISOString(),
        this.globalPushForwardInterval
      );
      this.popUpMsgTitle = "Sorry";
      this.popUpMsgBody =
        "This bit is not ready yet - for the time being please just email us at mediaorders@thanku.app and let us know what type of business you have and how many people will receive tips at it";
      this.showPopUpOk = true;
    },
    doRecipientSelected() {
      //this.$router.back();

      let returnJSON = {
        selectedTUID: "00000000002",
      };

      this.$emit("return-json", returnJSON);
    },
    // doGetTipsShowMoreInfo() {
    //   // /console.log("show more info!");

    //   let returnJSON = { showMoreGetInfoNew: true };
    //   this.$emit("return-json", returnJSON);
    // },
    // doGetTipsShowMoreInfoFees() {
    //   // /console.log("show more info!");

    //   let returnJSON = { showMoreGetInfoNew: true, feesShow: true };
    //   this.$emit("return-json", returnJSON);
    // },
    goBack() {
      // console.log("we're off! Next screen is " + window.localStorage.getItem("nextScreen"));
      if (window.localStorage.getItem("nextScreen") !== null && window.localStorage.getItem("nextScreen") !== undefined) {
        let returnJSON = {
          nextScreen: window.localStorage.getItem("nextScreen"),
        };
        this.$emit("return-json", returnJSON);
        window.localStorage.removeItem("nextScreen");
      } else {
        let returnJSON = { showHome: true, };
        this.$emit("return-json", returnJSON);
      }
    },
    doSetMetas() {
      document.title = "Get free thankU cashless tipping QR badges";
      document
        .getElementsByTagName("meta")
        .namedItem("description")
        .setAttribute(
          "content",
          "Get free thankU cashless tipping QR badges - our stand-mounted thankU QR badges are just 6x4x2cm. They sit at the front desk and at your client seats (at your option). Simply tap the 'Order thankU badges' button below, tell us which design/colour you like (1, 2, or 3), how many you need, and the address to send them to - that's it!"
        );
    },
    async deployLogRocket() {
      var LRObjectId = new Date().getTime();

      if (window.localStorage.getItem("tuob") !== null) {
        LRObjectId = window.localStorage.getItem("tuob");
      }

      if (LRObjectId === "4g278vWUb1" || LRObjectId === "2fBkimFcIw") {
        return; // don't record myself
      }

      var LRDisplayname = "LR Name not given";

      if (window.localStorage.getItem("tudis") !== null) {
        LRDisplayname = window.localStorage.getItem("tudis");
      }

      try {
        LogRocket.init('thanku-alt-6/thanku-alt-6'); //LogRocket.init('fpi3vr/thanku');  //LogRocket.init('thanku-alt/tualt'); //LogRocket.init('thanku-alt-2/thanku-alt-2');//LogRocket.init('thanku-alt-10/thanku-alt-10'); //LogRocket.init('thanku-alt-9/thanku-alt-9'); //LogRocket.init('thanku-alt-5/thanku-alt-5'); //LogRocket.init('thanku-alt-4/thanku-alt-4'); //LogRocket.init('thanku-alt-3/thanku-alt-3');//LogRocket.init('thanku-alt-8/thanku-alt-8');//LogRocket.init("thanku-alt-7/thanku-alt-7"); //
        LogRocket.identify(LRObjectId, {
          name: LRDisplayname + ": get started",
          email: undefined,
          // Add your own custom user variables here, ie:
        });
      } catch (e) {
        // console.log("error starting LogRocket tipper id: " + this.tipper.objectId);

        const params = {
          toEmail: "appalert@thanku.app",
          subject: "ALERT! LogRocket initialisation error: " + e.message,
          body: "ALERT! LogRocket initialisation error: " + e.message,
        };

        Parse.Cloud.run("sendEmail", params);
        // fail silently do nothing
      }
    },
    startParse(){
      Parse.serverURL = "https://parseapi.back4app.com/";
      Parse.initialize(this.appId, this.appJSKey);
      console.log("initialised Parse");
    }
  },
  created() {
    console.log("creating me!");
    this.deployLogRocket();
    document.body.style.maxWidth = "100vw"; // for Google mobile crawler had to limit homepage width on body tag, this resets it
    this.doSetMetas();
    // console.log("Get Tips Start created");
    // console.log("window query: " + JSON.stringify(this.$route.params, null, 2));

    const fromScreen =
      this.$route.params !== undefined
        ? " from " + this.$route.params.fromScreen
        : this.fromScreen !== undefined
        ? this.fromScreen
        : "";

    let referrerURL =
      window.localStorage.getItem("referrerURL") !== null
        ? window.localStorage.getItem("referrerURL")
        : "";

    // console.log("MY REFERRERURL on GetTipsStart IS: " + referrerURL);

    // DEVTODO - just suppressed this during Dev
    shared.sendAppAlert(
      "Great! Someone has tapped the get tips button" + fromScreen + "!",
      referrerURL,
      this.appId,
      this.appJSKey
    );

    var extraMsg = "";

    if (window.localStorage.getItem("isnhbfmember") !== null) {
      // /console.log("this IS an NHBF member");
      window.localStorage.removeItem("isnhbfmember");
      this.nhbfmembers = true;
      extraMsg = "FOR NHBF MEMBER ";
    }
    shared.saveToUserPath(
      this.devEnv,
      "Get tips start process " + extraMsg + new Date().toISOString(),
      this.globalPushForwardInterval
    );
    this.doFontSizeComputed();
    window.addEventListener("resize", this.doFontSizeComputed);
  },
  mounted() {
    console.log("MOUNTME!");

    window.setTimeout(this.startParse, 1000); // we want to make it asyn to speed up page loading

    window.scrollTo(0, 0);
  },
};
</script>

<style>

html, body{
  overflow-x: hidden !important;
  max-width: 100vw !important;
  touch-action: pan-y; /** Disable horizontal scrolling */
  /* all ofhte above is to stop horizontal scrolling on safari mobile see https://stackoverflow.com/questions/15879710/disabling-horizontal-scroll-on-an-iphone-website */
}


/* // Small devices (landscape phones, 576px and up) THIS DOES MY IPHONE 12 IN LANDSCAPE, AND DESKTOP*/
/*  * @media (min-width: 576px) and (max-width: 767.98px) {  */
/* // Extra small devices (portrait phones, less than 576px) THIS DOES MY IPHONE 12 IN PORTRAIT*/
@media (max-width: 575.98px) {
  .hairdresser2imagewidth {
    width: 100%;
  }

  .productImageWidth {
    width: 80%;
  }

  .productImageWidthCountertop {
    width: 80%;
  }

  .discreetpackagephonewidth {
    margin: auto !important;
    width: 90vw !important;
  }

  .orderformphonewidth {
    margin: auto !important;
    width: 90vw !important;
  }

  .tugettipspagebuttonwidth {
    width: 80% !important;
  }

  .tugettipspage-textbox {
    margin: auto;
    font-size: 16px;
    height: clamp(24px, 5vh, 34px);
    padding-left: 12px;
    border:0.3px solid #b9b9c0;
    border-radius: 10px;
  }


}

@media (min-width: 576px) {
  .hairdresser2imagewidth {
    width: 80%;
  }

  .productImageWidth {
    width: 80%;
  }

  .productImageWidthCountertop {
    width: 83%;
  }

  .tugettipspagebuttonwidth {
    width: 50% !important;
  }

  .tugettipspage-textbox {
  
    margin: auto;
    font-size: 16px;
    height: 5vh;
    padding-left: 12px;
    border:0.3px solid #b9b9c0;
    border-radius: 10px;
  }

  
}
</style>

